import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { responsive, Font, rem, Color } from "../../utils/style";
import { orderArray } from "../../utils/order";

// Components
import PageSEO from "../../components/seo/Page";
import Container from "../../components/Container";
import Row from "../../components/Row";
import ValueProps from "../../components/product/ValueProps";
import BarrierBusters from "../../components/product/BarrierBusters";
import CircleNew from "../../components/home/Circle.New";
import CategoryCards from "../../components/product/CategoryCards";
import OverlayLink from "../../components/OverlayLink";
import Text from "../../components/Text";

// Redux
import { connect } from "react-redux";
import appSelectors from "../../store/app/selectors";

const PageContainer = styled.div`
  margin-top: 92px;

  ${responsive.sm`
    margin-top: 100px;
  `}

  ${responsive.md`
    margin-top: 112px;
  `}

  &.no-whitespace {
    margin-top: 48px;

    ${responsive.md`
      margin-top: 60px;
    `}
  }
`;

const PageContent = styled.div`
  z-index: 2;
  position: relative;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-size: ${rem(30)};
    letter-spacing: -0.4px;
    line-height: ${rem(36)};
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    letter-spacing: 0px;
    line-height: ${rem(26)};
    margin-bottom: 0;
  }

  div {
    height: 4px;
    width: 40px;
    display: block;
    background-color: ${Color.ritualBlue};
    margin-top: 24px;
  }

  ${responsive.sm`
    h1 {
      max-width: 360px;
    }
  `}

  ${responsive.md`
    margin-bottom: 64px;

    h1 {
      font-size: ${rem(56)};
      letter-spacing: -1.61px;
      line-height: ${rem(64)};
      margin-bottom: 32px;
      max-width: 614px;
    }

    p {
      font-size: ${rem(18)};
      letter-spacing: 0px;
      line-height: ${rem(28)};
    }

    div {
      margin-top: 40px;
    }
  `}

  ${responsive.lg`
    h1 {
      max-width: 670px;
    }
  `}
`;

const ProductSection = styled.div.attrs({
  role: "section",
  "aria-labelledby": "product-section-heading",
})`
  position: relative;
  overflow: hidden;

  ${responsive.sm`
    padding-bottom: 40px;
  `}

  ${responsive.md`
    padding-bottom: 80px;
  `}
`;

const CategoriesSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CategorySection = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:last-of-type {
    margin-bottom: 80px;

    ${responsive.md`
      margin-bottom: 120px;
    `}
  }

  h3 {
    display: none;

    ${responsive.sm`
      display: block;
      margin-bottom: 32px;
      font-size: ${rem(22)};
      letter-spacing: -0.2px;
      line-height: ${rem(32)};
      text-align: center;
    `}

    ${responsive.md`
      font-size: ${rem(48)};
      letter-spacing: -1.4px;
      line-height: ${rem(54)};
    `}

    em {
      ${Font.dutch};
    }

    ${responsive.md`
      margin-bottom: 56px;
    `}
  }
`;

const Divider = styled.div`
  display: ${p => (p.category === "Women" ? "none" : "flex")};
  height: 1px;
  background-color: rgba(0, 0, 0, 0.16);
  width: 100%;
  margin: 56px 0;

  ${responsive.sm`
    margin: 80px 0;
  `}

  ${responsive.md`
    margin-left: -20px;
    margin-right: -20px;
    width: 934px;
  `}

  ${responsive.lg`
    width: 1170px;
  `}
`;

const GraySection = styled.div.attrs({
  role: "section",
  "aria-labelledby": "barrier-buster-heading",
})`
  position: relative;
  overflow: hidden;
  background-color: rgba(242, 241, 245, 0.56);
  padding: 80px 0;

  ${responsive.md`
    padding: 120px 0;
  `}
`;

const PageColumn = styled.div.attrs({
  className: "col",
})`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding: 0;
  `};
`;

const BarrierBustersColumn = styled(PageColumn)`
  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const ValuePropsSection = styled.section`
  margin-bottom: 40px;

  ${responsive.sm`
    margin-bottom: 0;
    position: relative;
    z-index: 2;
  `}

  .divider {
    display: flex;
    height: 4px;
    width: 32px;
    background-color: ${Color.ritualBlue};
    margin-bottom: 24px;

    ${responsive.sm`
      display: none;
    `}
  }

  p {
    ${Font.circular};
    font-size: ${rem(22)};
    letter-spacing: -0.2px;
    line-height: ${rem(32)};
    text-align: left;
    margin-bottom: 0;

    ${responsive.sm`
      text-align: center;
    `}

    ${responsive.md`
      margin-bottom: 16px;
    `}
  }
`;

const BarrierBustersTitle = styled.h2`
  ${Font.circular};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 500;
  letter-spacing: -0.4px;
  color: ${Color.ritualBlue};
  text-align: center !important;
  margin: 0;

  ${responsive.md`
    font-size: ${rem(66)};
    line-height: ${rem(72)};
    letter-spacing: -2px;
    margin-bottom: 24px;
  `};
`;

const ProductCategoriesOrder = [
  "44TmdusDzEH95uGu0URjIr", // Women
  "71526baqpQqVsScHBZpIEl", // Men
  "16dqMm2vfVsgy539guPkkE", // Kids & Teens
];

export class ShopVitamins extends React.Component {
  constructor(props) {
    super(props);

    this.orderedProductCategories = this._getOrderedCategories(
      props.data.allContentfulProductCategory.nodes,
    );

    this.state = {
      seo: {
        pagePath: "/shop-vitamins",
        title: "Multivitamins for every life stage",
        description:
          "Formulated with what your body needs for all stages of your life.",
      },
    };
  }

  valuePropIcons = ["traceable", "vegan", "gluten", "non", "fillers"];

  _getOrderedCategories(allCategoryNodes) {
    return orderArray(
      allCategoryNodes,
      ProductCategoriesOrder,
      "contentful_id",
    );
  }

  barrierBusterItems() {
    return intl.unformattedTranslation("shop.barrier-busters");
  }

  renderCategory(category) {
    return (
      <CategorySection key={category.categoryName}>
        <Divider category={category.categoryName} />
        <h3>
          <Text
            id="product.category-cards.heading"
            defaultMessage="Essential for {category}"
            values={{
              category: <em>{category.categoryName}</em>,
            }}
          />
        </h3>
        <CategoryCards products={category.products} category={category} />
      </CategorySection>
    );
  }

  render() {
    const { isUserDataLoaded } = this.props;
    const valuePropLabels = intl.unformattedTranslation(
      "shop.value-prop-labels",
    );

    return (
      <>
        <PageSEO {...this.state.seo} />
        {isUserDataLoaded && (
          <OverlayLink
            heading={intl.t("shop.overlay.heading", "Build Your Bundle")}
            subheading={intl.t(
              "shop.overlay.subhead",
              "Get {amount} off your first bundled order of 2 or more products",
              { amount: intl.formatCurrency(10, { round: true }) },
            )}
            linkDestination="/build-a-bundle"
          />
        )}
        <PageContainer>
          <ProductSection>
            <PageContent>
              <Container>
                <Row>
                  <PageColumn>
                    <HeroContainer>
                      <h1 id="product-section-heading">
                        {intl.t(
                          "shop.header-copy.headline",
                          "Multivitamins for different life stages",
                        )}
                      </h1>
                      <div />
                    </HeroContainer>
                    <CategoriesSection>
                      {this.orderedProductCategories.map(category => {
                        return this.renderCategory(category);
                      })}
                    </CategoriesSection>
                  </PageColumn>
                </Row>
              </Container>

              <ValuePropsSection>
                <Container>
                  <Row>
                    <PageColumn>
                      <div className="divider" />
                      <p>
                        {intl.t(
                          "shop.header-copy.value-prop-headline",
                          "A multivitamin should contain key nutrients in absorbable forms—no shady extras.",
                        )}
                      </p>
                    </PageColumn>
                  </Row>
                </Container>
                <ValueProps
                  valueProps={valuePropLabels}
                  icons={this.valuePropIcons}
                />
              </ValuePropsSection>
            </PageContent>
            <CircleNew className="right bottom obscured" />
          </ProductSection>
          <GraySection>
            <PageContent>
              <Container>
                <Row>
                  <BarrierBustersColumn className="col-12 col-sm-10 offset-sm-1">
                    <BarrierBustersTitle id="barrier-buster-heading">
                      <Text
                        id="shop.barrier-buster.title"
                        defaultMessage="Making healthy habits easy"
                      />
                    </BarrierBustersTitle>
                    <BarrierBusters items={this.barrierBusterItems()} />
                  </BarrierBustersColumn>
                </Row>
              </Container>
            </PageContent>
            <CircleNew className="right top lighter" />
          </GraySection>
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isUserDataLoaded: appSelectors.isUserDataLoaded(state),
  };
};

export default connect(mapStateToProps)(ShopVitamins);

export const query = graphql`
  query ShopVitaminsQuery($locale: String!) {
    allContentfulProductCategory(filter: { node_locale: { eq: $locale } }) {
      nodes {
        categoryName
        id
        contentful_id
        products {
          ...ProductCardFragment
          price
          sku
          stockStatus
          alternateThumbnail {
            fixed(width: 204, height: 204, quality: 90) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
            description
          }
          socialImage {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;
