import React from "react";
import styled from "styled-components";
import smoothscroll from "smoothscroll-polyfill";

// Utils
import NameMap from "../../utils/nameMap";
import { Color, rem, responsive } from "../../utils/style";

// Components
import { ProductCard } from "./ProductLandingCards";
import Text from "../Text";

// Redux
import { connect } from "react-redux";
import { addProductToCart } from "../../store/cart/actions";

// Styled Elements
const Grid = styled.div.attrs(p => ({
  id: `CategoryCards${p.categoryName}`,
}))`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-top: 64px;

  ${responsive.sm`
    padding-top: 0;
  `};

  ${responsive.md`
    margin-left: -25px;
    margin-right: -25px;
  `}
`;

const MobileNavigation = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  padding: 0 16px 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  width: 335px;

  ${responsive.sm`
    display: none;
  `};

  h3 {
    font-size: ${rem(22)};
    letter-spacing: -0.2px;
    line-height: ${rem(32)};
    text-align: center;
    display: block !important;
    margin-bottom: 0;

    ${responsive.sm`
      display: none;
    `}
  }

  &.fixed {
    position: fixed;
    top: 52px;
    left: calc(50% - (335px / 2));
    right: 0px;
    z-index: 10;
    background-color: ${Color.white};
    padding: 8px 16px 8px;
    width: 335px;
  }
`;

export const CategoryCards = class CategoryCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      floatNavigation: false,
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    // Adds polyfill for window.scroll({params})
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll#Browser_Compatibility
    smoothscroll.polyfill();
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    this.navigationOnScroll();
  }

  navigationOnScroll() {
    const { floatNavigation } = this.state;
    const { category } = this.props;

    const element = document.getElementById(
      `CategoryCards${category.categoryName}`,
    );

    // Distance from top of element to top of viewport
    const topPosition = element.getBoundingClientRect().top;

    // Determines if we should `absolute: fixed` the navigation
    //
    // The 2nd part of this argument is negative as the element has
    // scrolled past the top of the viewport, so we check if its between
    // 8px from top of viewport and negative ElementHeight from top of viewport
    const shouldDisplayFixed =
      topPosition <= 60 && topPosition >= 0 - element.offsetHeight;

    // Wrapped in statements to protect setting state every px scrolled
    if (!floatNavigation && shouldDisplayFixed) {
      this.setState({
        floatNavigation: true,
      });
    } else if (floatNavigation && !shouldDisplayFixed) {
      this.setState({
        floatNavigation: false,
      });
    }
  }

  render() {
    const { floatNavigation } = this.state;
    const { products, dispatchAddProductToCart, category } = this.props;

    if (!products) return null;

    return (
      <Grid categoryName={category.categoryName} key={category.categoryName}>
        <MobileNavigation className={floatNavigation ? "fixed" : ""}>
          <h3>
            <Text
              id="product.category-cards.heading"
              defaultMessage="Essential for {category}"
              values={{
                category: <em>{category.categoryName}</em>,
              }}
            />
          </h3>
        </MobileNavigation>
        {products.map((product, index) => {
          return (
            <ProductCard
              id={index}
              key={NameMap(product.name).plain}
              index={index}
              product={product}
              dispatchAddProductToCart={dispatchAddProductToCart}
            />
          );
        })}
      </Grid>
    );
  }
};

export default connect(null, {
  dispatchAddProductToCart: addProductToCart,
})(CategoryCards);
